import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  Alert,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

const baseUrl = "https://api.jinnhire.in/jinnhire/data/";
const getFileUrl = `${baseUrl}resume-processing/get-file/`;
const markFileCompletedUrl = `${baseUrl}resume-processing/mark-file-completed/`;

const credentials = {
  email: "mail_automation@nexiiconsulting.com",
  password: "password",
};

const TextMappingComponent = () => {
  const navigate = useNavigate();
  const [resumeid, setResumeId] = useState();
  const [content,setContent]=useState();
  const [lines, setLines] = useState([]); // Unmapped text lines
  const [mappings, setMappings] = useState({}); // Mapped text lines organized by section
  const [selectedTextBox, setSelectedTextBox] = useState(null); // Currently selected text box
  const [editingText, setEditingText] = useState({}); // Track editing states

  const [options] = useState([
    "Contact Information",
    "Professional Summary / Objective",
    "Core Skills / Key Competencies",
    "Work Experience",
    "Projects",
    "Education",
    "Certifications",
    "Awards and Achievements",
    "Languages",
    "Hobbies and Interests",
    "References",
    "Volunteer Experience",
    "Technical Summary",
    "Declaration",
    "Bucket",
  ]); // Dropdown options
  const [feedback, setFeedback] = useState({ message: "", type: "" }); // Feedback messages
  const [fileName, setFileName] = useState(""); // Store the actual file name
  const token = localStorage.getItem("authToken");

  // Fetch text data (simulate with static data for demo)
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (token) {
      const fileData = await getNextFile(token);
      if (fileData) {
        const content = fileData.content;
        setContent(content);
        setFileName(fileData.file_name); // Set the actual file name

        const processedLines = [];

        // Regex patterns for both conditions
        const condition1Pattern = /\n{2,}[:\-]\n{2,}/; // Condition 1: 2+ \n before and after ":" or ":-"
        const condition2Pattern = /^\n{2,}[\s\S]*?\n{2,}$/; // Condition 2: Starts and ends with 2+ \n

        // Split the content into sections by 2 or more newlines
        const sections = content.split(/\n{2,}/);

        sections.forEach((section) => {
          const trimmedSection = section.trim();

          // Check for condition 1: ":" or ":-" with 2+ newlines before and after
          if (condition1Pattern.test(trimmedSection)) {
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          }
          // Check for condition 2: Starts and ends with 2+ newlines
          else if (condition2Pattern.test(`\n${trimmedSection}\n`)) {
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          } else {
            // For all other cases, treat as a regular paragraph
            processedLines.push(trimmedSection.replace(/\n+/g, " ").trim());
          }
        });

        // Update the state with the processed lines
        setLines(processedLines);
      }
    }
  };

  const getNextFile = async (token) => {
    try {
      const response = await fetch(getFileUrl, {
        method: "GET",
        headers: { Authorization: `Token ${token}` },
      });

      if (response.ok) {
        const data = await response.json();
        console.log(`Fetched File: ${data.file_name}`);
        setResumeId(data.resume_id);
        return data;
      } else if (response.status === 204) {
        console.log("No files available for processing.");
      } else {
        const errorData = await response.json();
        console.error(
          `Failed to fetch file: ${response.status} - ${JSON.stringify(
            errorData
          )}`
        );
      }
    } catch (error) {
      console.error("Error fetching file:", error);
    }
    return null;
  };

  const handleSelect = (lineIndex, section) => {
    const selectedLine = lines[lineIndex];

    setMappings((prevMappings) => {
      const updatedMappings = { ...prevMappings };

      if (!updatedMappings[section]) {
        updatedMappings[section] = [];
      }

      updatedMappings[section].push(selectedLine);

      return updatedMappings;
    });

    setLines((prevLines) =>
      prevLines.filter((_, index) => index !== lineIndex)
    );

    setSelectedTextBox(null);
  };

  const handleTextBoxClick = (index) => {
    setSelectedTextBox(index);
  };

  const handleEdit = (section, lineIndex) => {
    setEditingText((prev) => ({
      ...prev,
      [`${section}-${lineIndex}`]: mappings[section][lineIndex],
    }));
  };

  const handleSave = (section, lineIndex) => {
    const editedText = editingText[`${section}-${lineIndex}`];
    setMappings((prevMappings) => {
      const updatedMappings = { ...prevMappings };
      updatedMappings[section][lineIndex] = editedText;
      return updatedMappings;
    });
    setEditingText((prev) => {
      const updatedEditingText = { ...prev };
      delete updatedEditingText[`${section}-${lineIndex}`];
      return updatedEditingText;
    });
  };

  const handleSubmit = async () => {
    if (lines.length > 0) {
      setFeedback({
        message: "Please map all lines before submitting.",
        type: "error",
      });
      return;
    }

    try {
      if (fileName && token) {
        await markFileCompleted(token, fileName);
      }
    } catch (error) {
      setFeedback({
        message: "An error occurred while submitting data.",
        type: "error",
      });
    }
  };

  async function markFileCompleted(token, fileName) {
    try {
      const response = await fetch(markFileCompletedUrl, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file_name: fileName,
          section_name: options,
          mapped_data: mappings,
          resume_id: resumeid,
        }),
      });

      if (response.ok) {
        console.log(`File marked as completed: ${fileName}`);
        setMappings("");
        fetchData();
      } else {
        const errorData = await response.json();
        console.error(
          `Failed to mark file as completed: ${
            response.status
          } - ${JSON.stringify(errorData)}`
        );
      }
    } catch (error) {
      console.error("Error marking file section as completed:", error);
    }
  }

  return (
    <>
   

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            justifySelf: "center",
            textAlign: "center",
            width: "80%",
            padding: "10px",
            borderRadius: "16px",
            fontWeight: "800px",
            background: "linear-gradient(314deg, #FEDC2A, #DD5789,#7A2C9E)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: "38px",
          }}
        >
          Your entries make a difference — Every keystroke brings us closer to
          success. Keep going, you're doing great!
        </Typography>
        <Button
          onClick={() => {
            navigate("/");
          }}
          sx={{
            marginTop: "14px",
            color: "white",
            textTransform: "none",
            padding: "10px",
            borderRadius: "3px",
            backgroundColor: "rgb(34,58,80,0.9)",
          }}
        >
          <HomeIcon />
        </Button>
      </Box>
      <Box
        mt={6}
        p={3}
        sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      >
        
        <Box sx={{ display: "flex", gap: 4 }}>
  {/* Refer Resume Section */}
  <Box
    sx={{
      mt: 4,
      p: 2,
      border: "1px solid lightgray",
      borderRadius: "8px",
      backgroundColor: "#F9FAFC",
      width:"35%",
    }}
  >
    <Typography
      variant="h5"
      gutterBottom
      sx={{
        fontWeight: "bold",
        paddingBottom: "8px",
        borderBottom: "2px solid lightblue",
        marginBottom: "1rem",
      }}
    >
      Refer Resume Section
    </Typography>
    {content ? (
      <Typography
        variant="body1"
        sx={{
          whiteSpace: "pre-wrap", // Preserve newlines and formatting
          wordWrap: "break-word", // Handle long words gracefully
          backgroundColor: "#E5F2FF",
          borderRadius: "8px",
          padding: "10px",
          border: "1px solid lightblue",
          lineHeight: "1.6",
          fontSize:'12px',
        }}
      >
        {content}
      </Typography>
    ) : (
      <Typography variant="body1" sx={{ color: "gray", textAlign: "center" }}>
        No content available to refer yet.
      </Typography>
    )}
  </Box>
          <Box sx={{ flex: 1, width: "32%" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                marginLeft: "20px",
                width: "fit-content",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid lightblue",
                fontWeight: "bold",
                background: "linear-gradient(to bottom, #ff8a00, #e52e71)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "1.5rem",
              }}
            >
              Unmapped Sections
            </Typography>
            <Grid container spacing={2}>
              {lines.map((line, index) => (
                <Grid item xs={12} key={index}>
                  <Card>
                    <CardContent>
                      <TextField
                        sx={{ width: "100%", marginBottom: "10px" }}
                        value={line}
                        variant="outlined"
                        onClick={() => handleTextBoxClick(index)}
                      />
                      {selectedTextBox === index && (
                        <Select
                          sx={{
                            width: "100%",
                            border: "1px solid lightblue",
                            backgroundColor: "#E5F2FF",
                          }}
                          value=""
                          onChange={(e) => handleSelect(index, e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="" disabled>
                            Select Section
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ flex: 1, width: "33%" }}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                width: "fit-content",
                padding: "10px",
                borderRadius: "8px",
                border: "1px solid lightblue",
                fontWeight: "bold",
                background: "linear-gradient(to right, red, blue)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginBottom: "1.5rem",
              }}
            >
              Mapped Sections
            </Typography>
            {options.map((section) => (
              <Box key={section} sx={{ marginBottom: 3 }}>
                <Typography variant="h6">{section}</Typography>
                {mappings[section] && mappings[section].length > 0 ? (
                  mappings[section].map((line, index) => (
                    <Card
                      key={index}
                      sx={{
                        marginBottom: 1,
                        backgroundColor: "#e6f7e6",
                        border: "1px solid #4caf50",
                      }}
                    >
                      <CardContent
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {editingText[`${section}-${index}`] ? (
                          <TextField
                            sx={{ width: "100%" }}
                            value={editingText[`${section}-${index}`]}
                            onChange={(e) =>
                              setEditingText((prev) => ({
                                ...prev,
                                [`${section}-${index}`]: e.target.value,
                              }))
                            }
                          />
                        ) : (
                          <Typography variant="body1">{line}</Typography>
                        )}
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() =>
                              editingText[`${section}-${index}`]
                                ? handleSave(section, index)
                                : handleEdit(section, index)
                            }
                          >
                            {editingText[`${section}-${index}`] ? (
                              <SaveIcon />
                            ) : (
                              <EditIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No mapped text
                  </Typography>
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{ width: "30%", margin: "auto" }}
          onClick={handleSubmit}
          disabled={lines.length > 0}
        >
          Submit
        </Button>

        <Snackbar
          open={!!feedback.message}
          autoHideDuration={3000}
          onClose={() => setFeedback({ message: "", type: "" })}
        >
          <Alert
            severity={feedback.type}
            onClose={() => setFeedback({ message: "", type: "" })}
          >
            {feedback.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};

export default TextMappingComponent;
