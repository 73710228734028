import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from "react";
import TextMappingComponent from './components/TextMappingComponent';
import GetStarted from './components/Getstarted';


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<GetStarted />} />
        <Route path="/main" element={<TextMappingComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
