import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const GetStarted = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(''); // Define setToken here
  const [open, setOpen] = useState(false); // Modal open state
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState(''); // For displaying errors

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // API call for login
    try {
      const response = await axios.post(
        'https://api.jinnhire.in/jinnhire/data/users/login/',
        formData
      );
  
      if (response.status === 200) {
        console.log('Login successful:', response.data);
  
        // Extract the token from the response
        const token = response.data?.token;
  
        if (token) {
          // Optional: Store the token in localStorage/sessionStorage
          localStorage.setItem('authToken', token);
  
          // Pass the token to the component/state
          setToken(token); // Assuming `setToken` is a state setter in the component
        }
  
        handleClose(); // Close the modal
        navigate('/main'); // Redirect to the main page
      }
    } catch (error) {
      console.error('Error during login:', error);
  
      // Handle error
      setErrorMessage('Invalid email or password. Please try again.');
    }
  };
  

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: "url('Assets/mappingResumes.svg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
      }}
    >
      {/* Tagline */}
      <h1
        style={{
          fontSize: '2rem',
          width: '40%',
          fontWeight: 'bold',
          background: 'linear-gradient(90deg, #ff8a00, #e52e71)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          marginBottom: '1.5rem',
        }}
      >
        Boost Your Productivity: Every Entry Brings Us Closer to Success!
      </h1>

      {/* Bullet Points */}
      <ul
        style={{
          fontSize: '1.2rem',
          color: '#333',
          alignItems: 'center',
          marginBottom: '2rem',
          textAlign: 'left',
          paddingLeft: '20px',
          listStyleType: 'none',
        }}
      >
        {[
          'Accelerate your workflow: Every entry helps streamline the process and save time.',
          'Unlock valuable insights: More data means better analysis and smarter decisions.',
          'Contribute to success: Your entries directly impact growth and efficiency.',
          'Enhance accuracy: The more entries, the more refined and precise our results become.',
          'Make a difference: Each entry plays a key role in achieving our goals together.',
        ].map((item, index) => (
          <li
            key={index}
            style={{
              position: 'relative',
              marginBottom: '1.5rem',
              paddingLeft: '30px',
              fontWeight: '500',
              fontSize: '1rem',
            }}
          >
            <strong>{item.split(':')[0]}:</strong> {item.split(':')[1]}
          </li>
        ))}
      </ul>

      {/* Get Started Button */}
      <button
        style={{
          padding: '1rem 2rem',
          fontSize: '1rem',
          fontWeight: 'bold',
          color: '#fff',
          background: '#007BFF',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: 'all 0.3s ease',
        }}
        onClick={handleOpen}
        onMouseEnter={(e) => (e.target.style.background = '#0056b3')}
        onMouseLeave={(e) => (e.target.style.background = '#007BFF')}
      >
        Get Started
      </button>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ marginBottom: 2, textAlign: 'center' }}>
            Login
          </Typography>

          {/* Error Message */}
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ marginBottom: 2 }}>
              {errorMessage}
            </Typography>
          )}

          {/* Form */}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 2 }}
            >
              Login
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default GetStarted;
